import { GeneralLinks } from '@/data/superstack/GeneralLinks';

export const getJobDisplayingUrlObj = (
  filteredRoles,
  filteredLevels,
  filteredStacks,
  filteredSigungus,
  keyword,
  sort,
  page,
  query = {},
) => {
  const queryFilters = JSON.stringify({
    filteredRoles: filteredRoles || [],
    filteredLevels: filteredLevels || [],
    filteredStacks: filteredStacks || {},
    filteredSigungus: filteredSigungus || [],
  });
  return {
    pathname: GeneralLinks().find((link) => link.name === 'displaying_list')?.url,
    query: {
      ...query,
      filter_data: queryFilters,
      keyword: keyword || '',
      sort: sort || 'default',
      page: page || 1,
    },
  };
};
