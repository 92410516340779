import { deleteCookie, getCookie, setCookie } from 'cookies-next';

const hasCultureBubbleClickedCookie = () => {
  return !!getCookie('hasCultureBubbleClicked');
};

const setHasCultureBubbleClickedCookie = () => {
  deleteCookie('hasCultureBubbleClicked');
  setCookie('hasCultureBubbleClicked', true);
};

export { hasCultureBubbleClickedCookie, setHasCultureBubbleClickedCookie };
