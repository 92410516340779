import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from '@/styles/superstack/base/Tabs.module.scss';
import PropTypes from 'prop-types';
import StackImage from '@/src/component/superstack/stacks/StackImage';
import Ellipsis from '@/asset/superstack/svgs/ellipsis.svg';
import { useOutsideClick } from '@/src/utils/hooks/useOutsideClick';
import { CompanyGeneralLinks } from '@/data/superstack/companies/CompanyGeneralLinks';
import { useRouter } from 'next/router';
import { api } from '@/src/utils/api';
import { ApiUrls } from '@/data/superstack/ApiUrls';
import { handleServerError } from '@/src/utils/ss_utils';
import {
  hasCultureBubbleClickedCookie,
  setHasCultureBubbleClickedCookie,
} from '@/src/services/superstack/jobs/cookies';

const Tabs = ({ tabNames, activeTabName, updateContainer, ...props }) => {
  const cx = classNames.bind(styles);
  const [activeTabName_, setActiveTabName_] = useState('');
  const handleClick = (clickedTabName) => {
    if (updateContainer) {
      updateContainer(clickedTabName);
    }
  };

  useEffect(() => {
    setActiveTabName_(activeTabName || tabNames[0]);
  }, [tabNames, activeTabName]);

  const tabs = tabNames
    .filter((tabName) => !!tabName)
    .map((tabName, idx) => {
      return (
        <Tab
          key={idx}
          name={tabName}
          isActive={tabName === activeTabName_}
          updateContainer={(clickedTabName) => {
            setActiveTabName_(clickedTabName);
            handleClick(clickedTabName);
          }}
        />
      );
    });
  return (
    <div className={cx('tabs')} {...props}>
      {tabs}
    </div>
  );
};

const Tab = ({ name, isActive, updateContainer }) => {
  const cx = classNames.bind(styles);
  const cns = isActive ? ['tab', 'active'] : ['tab'];

  return (
    <span
      className={cx(cns)}
      onClick={() => {
        updateContainer(name);
        if (name === '컬처코드' && !hasCultureBubbleClickedCookie()) {
          setHasCultureBubbleClickedCookie(true);
        }
      }}
    >
      {name}
    </span>
  );
};

const BoxTabs = ({
  tabNames,
  activeTabName,
  updateContainer,
  countList,
  unit,
  faint = false,
  ...props
}) => {
  const cx = classNames.bind(styles);
  const [activeTabName_, setActiveTabName_] = useState(activeTabName || tabNames[0]);

  const handleClick = (clickedTabName) => {
    if (updateContainer) {
      updateContainer(clickedTabName);
    }
  };
  useEffect(() => {
    if (props.selectedListTab) {
      setActiveTabName_(props.selectedListTab);
    }
  }, [props.selectedListTab]);
  const tabs = tabNames.map((tabName, idx) => {
    return (
      <BoxTab
        key={idx}
        name={tabName}
        isActive={tabName === activeTabName_}
        updateContainer={(clickedTabName) => {
          setActiveTabName_(clickedTabName);
          handleClick(clickedTabName);
        }}
        count={countList?.[tabName]}
        unit={unit}
      />
    );
  });
  return <div className={cx(['apply-tabs', faint ? 'faint' : ''])}>{tabs}</div>;
};

const BoxTab = ({ name, isActive, updateContainer, count, unit, ...props }) => {
  const cx = classNames.bind(styles);
  const cns = isActive ? ['apply-tab', 'apply-tab-active'] : ['apply-tab'];

  return (
    <span
      className={cx(cns)}
      onClick={() => {
        updateContainer(name);
      }}
      {...props}
    >
      {name}
      {typeof count === 'number' && (
        <span>
          &nbsp; ({count}
          {unit})
        </span>
      )}
    </span>
  );
};

const ScrollableTabs = ({
  Tab,
  tabNames,
  tabFlags,
  activeTabFlag,
  updateContainer,
  additionalLink = null,
}) => {
  const cx = classNames.bind(styles);
  const [activeTabFlag_, setActiveTabFlag_] = useState('');
  const scrollRef = useRef(null);
  let isDragging = false;
  let startPos = 0;
  let scrollLeft = 0;

  const handleClick = (clickedTabFlag) => {
    if (updateContainer) {
      updateContainer(clickedTabFlag);
    }
  };

  useEffect(() => {
    setActiveTabFlag_(activeTabFlag_ || tabFlags[0]);
  }, [tabFlags, activeTabFlag]);

  const startDrag = (e) => {
    isDragging = true;
    startPos = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft = scrollRef.current.scrollLeft;
    scrollRef.current.style.cursor = 'grabbing';
  };

  const endDrag = () => {
    isDragging = false;
    scrollRef.current.style.cursor = 'pointer';
  };

  const performDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = x - startPos;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const tabs = tabNames
    .filter((tabName) => !!tabName)
    .map((tabName, idx) => {
      if (tabName === '|') {
        return <span key={idx} className={cx('separator')} />;
      }
      return (
        <Tab
          key={idx}
          name={tabName}
          flag={tabFlags[idx]}
          isActive={tabFlags[idx] === activeTabFlag_}
          updateContainer={(clickedTabFlag) => {
            setActiveTabFlag_(clickedTabFlag);
            handleClick(clickedTabFlag);
          }}
        />
      );
    });
  return (
    <div
      className={cx('scrollable-tabs')}
      ref={scrollRef}
      onMouseDown={startDrag}
      onMouseLeave={endDrag}
      onMouseUp={endDrag}
      onMouseMove={performDrag}
    >
      {tabs}
      {additionalLink}
    </div>
  );
};

const CompanyHomeTab = ({ name, flag, isActive, updateContainer }) => {
  const cx = classNames.bind(styles);
  const cns = isActive ? ['company-home-tab', 'active'] : ['company-home-tab'];

  return (
    <span
      className={cx(cns)}
      onClick={() => {
        updateContainer(flag);
      }}
    >
      <p>{name}</p>
    </span>
  );
};

const StackTab = ({ name, flag, isActive, updateContainer }) => {
  const cx = classNames.bind(styles);
  const cns = isActive ? ['stack-tab', 'active'] : ['stack-tab'];

  return (
    <span
      className={cx(cns)}
      onClick={() => {
        updateContainer(flag);
      }}
    >
      <StackImage flag={flag} width={40} height={40} alt={name} />
      <p>{name}</p>
    </span>
  );
};

const ServiceTab = ({ name, flag, isActive, updateContainer }) => {
  const cx = classNames.bind(styles);
  const cns = isActive ? ['service-tab', 'active'] : ['service-tab'];

  return (
    <span
      className={cx(cns)}
      onClick={() => {
        updateContainer(flag);
      }}
    >
      <p>{name}</p>
    </span>
  );
};

const MyServiceTab = ({ name, flag, isActive, updateContainer }) => {
  // Todo: ScrollableTabs 스크롤시 TabDropdown 영역이 밀려나는 에러 수정
  const router = useRouter();
  const { id: companyId } = router.query;
  const cx = classNames.bind(styles);
  const cns = isActive ? ['my-service-tab', 'active'] : ['my-service-tab'];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useOutsideClick(() => {
    setIsDropdownOpen(false);
  });

  const handleClickEllipsis = () => {
    setIsDropdownOpen((current) => !current);
  };
  const handleClickEdit = () => {
    let formUrl;
    if (flag === 0) {
      formUrl = CompanyGeneralLinks(companyId).find(
        (link) => link.name === 'company_manage_stacks_form',
      )?.url;
    } else {
      formUrl =
        CompanyGeneralLinks(companyId).find((link) => link.name === 'company_service_form')?.url +
        `?serviceId=${flag}`;
    }
    window.location.href = formUrl;
  };
  const handleClickDelete = async () => {
    const url = ApiUrls(companyId).find((url) => url.name === 'company_service_list').url;
    if (confirm(`${name} 프로덕트를 삭제하시겠습니까?`)) {
      try {
        await api.delete(url + `/${flag}`);
        await router.reload(window.location.pathname);
      } catch (error) {
        handleServerError(error);
      }
    }
  };

  return (
    <span
      className={cx(cns)}
      onClick={() => {
        updateContainer(flag);
      }}
    >
      <p>{name}</p>
      <span onClick={handleClickEllipsis} ref={dropdownRef}>
        <Ellipsis width={15} height={15} fill={'#212121'} />
        <TabDropdown
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          handleClickEdit={handleClickEdit}
          withDelete={flag !== 0}
          handleClickDelete={handleClickDelete}
        />
      </span>
    </span>
  );
};

const TabDropdown = ({
  isDropdownOpen,
  setIsDropdownOpen,
  handleClickEdit,
  withDelete,
  handleClickDelete,
}) => {
  const cx = classNames.bind(styles);

  if (isDropdownOpen) {
    return (
      <div className={cx(['tab-dropdown'])}>
        <div className={cx(['tab-dropdown-item'])} onClick={handleClickEdit}>
          수정하기
        </div>
        {withDelete && (
          <div
            className={cx(['tab-dropdown-item'])}
            onClick={() => {
              handleClickDelete();
              setIsDropdownOpen(false);
            }}
          >
            삭제
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

Tabs.propTypes = {
  tabNames: PropTypes.array,
  activeTabName: PropTypes.string,
};

Tabs.defaultProps = {
  tabNames: [],
  activeTabName: '',
};

export { Tabs, BoxTabs, ScrollableTabs, CompanyHomeTab, StackTab, ServiceTab, MyServiceTab };
