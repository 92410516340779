import { useMemo, useState } from 'react';
import { api } from '@/src/utils/api';
import useSWRImmutable from 'swr/immutable';
import { ApiUrls } from '@/data/superstack/ApiUrls';
import { useRouter } from 'next/router';

import { SSLayout } from '@/src/layout/superstack/SSLayout';
import styles from '@/styles/superstack/landing.module.scss';
import classNames from 'classnames/bind';

import ImgPAP from '@/asset/superstack/pngs/img-paper-airplane.png';
import ImgLandingCultureCode from '@/asset/superstack/pngs/img-landing-culture-code.png';
import ImgLandingSkill from '@/asset/superstack/pngs/img-landing-skill.png';
import ImgLandingProfile from '@/asset/superstack/pngs/img-landing-profile.png';
import ImgLandingHand from '@/asset/superstack/pngs/img-landing-hand.png';
import ImgLandingSpace from '@/asset/superstack/pngs/img-landing-space.png';
import ImgLandingCoreValue from '@/asset/superstack/pngs/img-landing-core-value.png';
import IconThumbUp from '@/asset/superstack/svgs/icon-thumb-up.svg';
import IconFlagRed from '@/asset/superstack/svgs/icon-flag-red.svg';
import IconHome from '@/asset/superstack/svgs/icon-home.svg';
import ImgLandingCarousel1 from '@/asset/superstack/svgs/img-landing-carousel-1.svg';
import ImgLandingCarousel2 from '@/asset/superstack/svgs/img-landing-carousel-2.svg';
import ImgLandingCarousel3 from '@/asset/superstack/svgs/img-landing-carousel-3.svg';
import IconArrowRight from '@/asset/superstack/svgs/icon-arrow-right.svg';

import { Button } from '@/src/component/superstack/base/Button';
import LinkTag from '@/src/component/LinkTag';
import { GeneralLinks } from '@/data/superstack/GeneralLinks';
import { CompanyGeneralLinks } from '@/data/superstack/companies/CompanyGeneralLinks';
import SSImage from '@/src/component/superstack/base/SSImage';
import { SSHeaderLanding } from '@/src/layout/superstack/SSHeader';
import useSSProfile from '@/src/utils/hooks/superstack/useSSProfile';
import { CardJobLanding } from '@/src/component/superstack/jobs/cards/CardJob';
import { landingStacks } from '@/src/consts/superstack';
import FadeLoader from 'react-spinners/FadeLoader';
import { ButtonLinkRear } from '@/src/component/superstack/base/ButtonLink';
import { getJobDisplayingUrlObj } from '@/src/utils/superstack/jobs';
import { handleClickSignUpEvt } from '@/src/utils/tracking_analytics';
import { ScrollableTabs, StackTab } from '@/src/component/superstack/base/Tabs';
import { JobLinks } from '@/data/superstack/JobLinks';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CarouselArrowNext } from '@/src/component/superstack/base/button/CarouselArrow';

const cx = classNames.bind(styles);

const Landing = ({ isMobile }) => {
  const { user: { data: user = {} } = {} } = useSSProfile();

  const landingStacksShuffled = useMemo(() => {
    return landingStacks.sort(() => Math.random() - 0.5);
  }, []);

  return (
    <SSLayout
      pageTitle={'내게 잘 맞는 일, 우리와 어울리는 사람 | 위시켓 잡스'}
      width={'100%'}
      Header={SSHeaderLanding}
    >
      <section
        className={cx('container')}
        style={{ maxWidth: '100%', marginTop: '20px', padding: 0, marginBottom: '12px' }}
      >
        <LandingBannerWrapper />
      </section>
      <section className={cx('container')} style={isMobile ? {} : { marginTop: '90px' }}>
        <section className={cx('jobs')}>
          <h5 className={cx('title')}>내 기술 스택과 딱 맞는 포지션을 찾아보세요.</h5>
          <JobContainer isMobile={isMobile} landingStacks={landingStacksShuffled} />
        </section>
      </section>
      <section
        className={cx('culture-code')}
        style={
          isMobile
            ? { padding: '32px 24px', marginTop: '20px' }
            : { padding: '120px 0', marginTop: '120px' }
        }
      >
        <div className={cx('container')}>
          <div className={cx('content-wrapper')}>
            <div className={cx('title-wrapper')}>
              <h5 className={cx('title')}>회사의 컬처코드가 나와 맞는지 확인해보세요.</h5>
            </div>
            <div className={cx('content')}>
              <div className={cx('codes')}>
                <article className={cx('code')}>
                  <IconThumbUp width={24} height={24} />
                  <p>우리 회사는 일정보다 품질이 더 중요하다고 생각해요.</p>
                </article>
                <article className={cx('code')}>
                  <IconFlagRed width={24} height={24} />
                  <p>우리 회사는 기술 부채 최소화보다 비즈니스 목표 달성이 더 중요해요.</p>
                </article>
                <article className={cx('code')}>
                  <IconHome width={24} height={24} />
                  <p>우리 회사는 회사 생활과 사생활이 철저하게 분리되어 있어요.</p>
                </article>
              </div>
              {!isMobile && <SSImage src={ImgLandingCultureCode} width={400} height={232} />}
            </div>
          </div>
          <div className={cx('link-borderless')}>
            <LinkTag
              href={
                GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url
              }
              isNextPage={true}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '9px',
                textDecoration: 'none',
              }}
            >
              <p style={{ color: '#006DFF' }}>나와 컬처코드가 잘 맞는 회사 보러 가기</p>
              <IconArrowRight width={7} height={14} fill={'#006DFF'} />
            </LinkTag>
          </div>
        </div>
      </section>
      <section
        className={cx('container')}
        style={isMobile ? {} : { marginTop: '120px', marginBottom: '120px' }}
      >
        {!user.username && (
          <div className={cx('banner-wrapper')}>
            <div className={cx('banner')}>
              <div className={cx('content-wrapper')}>
                <SSImage src={ImgPAP} width={82} height={45} />
                <p className={cx(['content', 'pc'])}>회원가입하고 더 많은 공고를 확인해보세요.</p>
                <p className={cx(['content', 'mobile'])}>
                  회원가입하고 <br /> 더 많은 공고를 확인해보세요.
                </p>
              </div>
              <LinkTag
                href={`/wishket-member/login/?next=${
                  GeneralLinks().find((link) => link.name === 'displaying_list')?.url
                }`}
                isNextPage={false}
              >
                <Button
                  mode={'filled'}
                  size={'xl-landing'}
                  onClick={handleClickSignUpEvt}
                  style={{ borderRadius: '8px' }}
                >
                  지금 보러 가기
                </Button>
              </LinkTag>
            </div>
          </div>
        )}
      </section>
      <section className={cx('container')}>
        <section className={cx('zigzag')}>
          <div className={cx('element')}>
            <div className={cx(['img-png-box'])}>
              <SSImage width={520} height={335} src={ImgLandingSkill} />
            </div>
            <div className={cx('content')}>
              <h4 className={cx('title')}>
                100+가지 기술 스택으로
                <br />
                나에게 꼭 맞는 자리를 찾아보세요.
              </h4>
              <p className={cx(['subtitle', 'pc'])}>
                내가 가진 기술과 꼭 맞는 일을 찾기 어려우셨나요?
                <br />
                위시켓 잡스에서는 100가지 이상의 기술 스택을 비교하면서
                <br />
                내가 원하는, 잘할 수 있는 일자리를 쉽고 빠르게 찾을 수 있습니다.
              </p>
              <p className={cx(['subtitle', 'mobile'])}>
                내가 가진 기술과 꼭 맞는 일을 찾기 어려우셨나요? 위시켓 잡스에서는 100가지 이상의
                기술 스택을 비교하면서 내가 원하는, 잘할 수 있는 일자리를 쉽고 빠르게 찾을 수
                있습니다.
              </p>
              <LinkTag
                href={GeneralLinks().find((link) => link.name === 'displaying_list')?.url}
                isNextPage={true}
              >
                <a className={cx('link')}>
                  포지션 보러 가기
                  <IconArrowRight width={7} height={14} fill={'#006DFF'} />
                </a>
              </LinkTag>
            </div>
          </div>
          <div className={cx('element')}>
            <div className={cx(['img-png-box'])}>
              <SSImage src={ImgLandingProfile} width={519} height={335} />
            </div>
            <div className={cx('content')} style={isMobile ? {} : { paddingRight: '89px' }}>
              <h4 className={cx('title')}>
                위시켓 잡스에서만 볼 수 있는
                <br />
                실제 개발 경력이 담긴 프로필
              </h4>
              <p className={cx(['subtitle', 'pc'])}>
                기존의 개발 역량을 알아보기 어려운 이력서 대신,
                <br />
                실제 개발에 필요한 경력과 보유 기술을 보여주는
                <br />
                위시켓 잡스의 프로필을 이용해 보세요.
              </p>
              <p className={cx(['subtitle', 'mobile'])}>
                기존의 개발 역량을 알아보기 어려운 이력서 대신, 실제 개발에 필요한 경력과 보유
                기술을 보여주는 위시켓 잡스의 프로필을 이용해 보세요.
              </p>
              <LinkTag
                href={GeneralLinks().find((link) => link.name === 'ss_profile_mine_home')?.url}
                isNextPage={true}
              >
                <a className={cx('link')}>
                  프로필 작성하러 가기
                  <IconArrowRight width={7} height={14} fill={'#006DFF'} />
                </a>
              </LinkTag>
            </div>
          </div>
          <div className={cx('element')}>
            <div className={cx(['img-png-box'])}>
              <SSImage src={ImgLandingHand} width={521} height={335} />
            </div>
            <div className={cx('content')}>
              <h4 className={cx('title')}>
                복잡한 채용 공고,
                <br />
                구체화하기 어려우셨나요?
                <br />
                위시켓 잡스가 도와드릴게요.
              </h4>
              <p className={cx(['subtitle', 'pc'])}>
                정보가 구체적이어야 적합한 사람을 찾을 수 있습니다.
                <br />
                위시켓 잡스만의 꼼꼼한 프로필과 공고 검수 서비스를 통해
                <br />
                채용 조건과 기술 스택 등의 정보를 손쉽게 구체화해보세요.
              </p>
              <p className={cx(['subtitle', 'mobile'])}>
                정보가 구체적이어야 적합한 사람을 찾을 수 있습니다. 위시켓 잡스만의 꼼꼼한 프로필과
                공고 검수 서비스를 통해 채용 조건과 기술 스택 등의 정보를 손쉽게 구체화해보세요.
              </p>
              <LinkTag
                href={
                  CompanyGeneralLinks().find((link) => link.name === 'company_main_router')?.url
                }
                isNextPage={false}
              >
                <a className={cx('link')}>
                  공고 등록하러 가기
                  <IconArrowRight width={7} height={14} fill={'#006DFF'} />
                </a>
              </LinkTag>
            </div>
          </div>
        </section>
      </section>
      <section
        className={cx('culture')}
        style={isMobile ? { marginTop: '20px' } : { marginTop: '120px' }}
      >
        <div className={cx('content-wrapper')}>
          <div className={cx('title-wrapper')}>
            <h4 className={cx('title')}>
              일하기 좋은 문화, 일하기 좋은 사람
              <br />
              위시켓 잡스에서 찾아보세요.
            </h4>
          </div>
          <div className={cx('culture-card-wrapper')}>
            <section className={cx('culture-card')}>
              <SSImage src={ImgLandingSpace} width={340} height={160} />
              <h6 className={cx('title')}>
                내가 일할 공간을 <br />
                확인해보세요.
              </h6>
              <p className={cx('text')}>
                쾌적한 공간은 쾌적한 업무를 가능하게 합니다.
                <br />
                채용공고를 통해 내가 일할 공간을 확인해보세요.
              </p>
            </section>
            <section className={cx('culture-card')}>
              <SSImage src={ImgLandingCoreValue} width={340} height={160} />
              <h6 className={cx('title')}>
                회사의 핵심가치와 문화가
                <br />
                나와 맞는지 확인해보세요.
              </h6>
              <p className={cx('text')}>
                함께 비전을 달성하기 위해서는
                <br />
                직무뿐만 아니라 핵심 가치와 문화도 매우 중요합니다.
              </p>
            </section>
          </div>
        </div>
      </section>
      <section className={cx('faqs')}>
        <h4 className={cx('title')}>도움이 필요하신가요?</h4>
        <div className={cx('faq-container')}>
          <div className={cx('faq-wrapper')}>
            <h6 className={cx('title')}>개발자가 많이 묻는 질문</h6>
            <p className={cx('content')}>
              <LinkTag
                href={'https://wishketjobs.notion.site/598fa6c0eaf9413287ff006814e3acdf'}
                isNextPage={false}
                target={'_blank'}
              >
                Q.&nbsp;&nbsp;어떤 기업이 제 프로필(이력서)을 보게 되나요?
              </LinkTag>
            </p>
            <p className={cx('content')}>
              <LinkTag
                href={'https://www.notion.so/wishketjobs/b6340c86806b4261a7296e428bf9c1fe'}
                isNextPage={false}
                target={'_blank'}
              >
                Q.&nbsp;&nbsp;위시켓 프로필(이력서)을 위시켓 잡스에서도 이용할 수 있나요?
              </LinkTag>
            </p>
            <p className={cx('content')}>
              <LinkTag
                href={'https://wishketjobs.notion.site/c8b9e5d1ceee46c781d782f65d62dd12'}
                isNextPage={false}
                target={'_blank'}
              >
                Q.&nbsp;&nbsp;취업축하금은 어떻게 받나요?
              </LinkTag>
            </p>
          </div>
          <div className={cx('faq-wrapper')}>
            <h6 className={cx('title')}>기업이 많이 묻는 질문</h6>
            <p className={cx('content')}>
              <LinkTag
                href={'https://wishketjobs.notion.site/24dfba83d6914b60bbba1f1946bf2000'}
                isNextPage={false}
                target={'_blank'}
              >
                Q.&nbsp;&nbsp;기술 스택은 어떻게 등록하나요?
              </LinkTag>
            </p>
            <p className={cx('content')}>
              <LinkTag
                href={'https://wishketjobs.notion.site/da2d344f36b443069116d736644684eb'}
                isNextPage={false}
                target={'_blank'}
              >
                Q.&nbsp;&nbsp;채용공고는 어떻게 등록하나요?
              </LinkTag>
            </p>
            <p className={cx('content')}>
              <LinkTag
                href={'https://wishketjobs.notion.site/cc0221c8c7684dd69f54344017f937a4'}
                isNextPage={false}
                target={'_blank'}
              >
                Q.&nbsp;&nbsp;서비스 이용 시 비용은 얼마인가요?
              </LinkTag>
            </p>
          </div>
        </div>
        <p className={cx('show-more')}>
          <LinkTag
            href={GeneralLinks().find((link) => link.name === '자주 묻는 질문')?.url}
            isNextPage={false}
            target={'_blank'}
          >
            +더보기
          </LinkTag>
        </p>
      </section>
      <section className={cx('cta')}>
        <h4 className={cx(['title', 'pc'])}>회사의 기술 스택과 맞는 개발자를 찾고 싶으신가요?</h4>
        <h4 className={cx(['title', 'mobile'])}>
          회사의 기술 스택과 맞는 <br />
          개발자를 찾고싶으신가요?
        </h4>
        <p className={cx('subtitle')}>지금 여기서 꼭 맞는 개발자 공고를 등록해보세요.</p>
        <LinkTag
          href={CompanyGeneralLinks().find((link) => link.name === 'company_main_router')?.url}
          isNextPage={true}
        >
          <Button mode={'filled'} size={'xl-landing'}>
            공고 등록하러 가기
          </Button>
        </LinkTag>
      </section>
    </SSLayout>
  );
};

const JobContainer = ({ isMobile, landingStacks }) => {
  const router = useRouter();
  const [stackFlag, setStackFlag] = useState(landingStacks[0][1]);
  const stackName = landingStacks.filter((landingStack) => stackFlag === landingStack[1])[0]?.[0];

  let filteredStacks = {};
  if (stackFlag === 'spring') {
    filteredStacks['spring_boot'] = [0, 15];
    filteredStacks['spring_framework'] = [0, 15];
  } else {
    filteredStacks[stackFlag] = [0, 15];
  }

  const stackFilteredUrlObj = getJobDisplayingUrlObj(null, null, filteredStacks);
  const url = ApiUrls().find((link) => link.name === 'jobs_landing')?.url;
  const params = { stack_flag: stackFlag };
  const jobsFetcher = (url, params) => {
    return api
      .get(url, {
        params: params,
      })
      .then((result) => result.data);
  };
  const { data: jobs = [] } = useSWRImmutable([url, params], (url) => jobsFetcher(url, params));
  const jobs_ = isMobile ? jobs.slice(0, 3) : jobs.slice(0, 8);
  const isLoading = () => {
    return jobs.length === 0;
  };

  const handleClick = (job) => {
    const url = JobLinks(job.id).find((link) => link.name === 'job_detail').url;
    router.push(url, url);
  };
  return (
    <section className={cx('stack-job-container')}>
      <ScrollableTabs
        Tab={StackTab}
        tabNames={landingStacks.map((landingStack) => {
          return landingStack[0];
        })}
        tabFlags={landingStacks.map((landingStack) => {
          return landingStack[1];
        })}
        updateContainer={(clickedTabFlag) => {
          setStackFlag(clickedTabFlag);
        }}
      />
      <div className={cx('job-container')}>
        {!isLoading() &&
          jobs_.map((job) => {
            return (
              <CardJobLanding
                key={job.id}
                job={job}
                onClick={() => {
                  handleClick(job);
                }}
              />
            );
          })}
        <FadeLoader
          color={'gray'}
          loading={isLoading()}
          cssOverride={{
            margin: isMobile ? '281px auto' : '195px auto',
          }}
        />
      </div>
      {!isLoading() && (
        <div className={cx('position-link')}>
          <ButtonLinkRear
            mode={'borderless'}
            size={'xl'}
            onClick={() => {
              router.push(stackFilteredUrlObj, stackFilteredUrlObj);
            }}
          >
            <p>&apos;{stackName}&apos; 포지션 더 보러 가기</p>
          </ButtonLinkRear>
        </div>
      )}
    </section>
  );
};

const LandingBannerWrapper = () => {
  const items = [
    {
      img: <ImgLandingCarousel1 />,
      bgColor: '#F5FCF7',
      title: '기술스택 확인은 필수지!',
      btnUrl: GeneralLinks().find((link) => link.name === 'stacks_companies')?.url,
      btnTxt: '기술스택 확인하기',
    },
    {
      img: <ImgLandingCarousel2 />,
      bgColor: '#FFFCF2',
      title: '다 똑같은 공고 뭐하러 보니?',
      btnUrl: GeneralLinks().find((link) => link.name === 'displaying_list')?.url,
      btnTxt: '차원이 다른 공고 확인하기',
    },
    {
      img: <ImgLandingCarousel3 />,
      bgColor: '#FFF4F6',
      title: '이 회사 나랑 맞을까? 고민될 땐',
      btnUrl: GeneralLinks().find((link) => link.name === 'company_culture_review_results')?.url,
      btnTxt: '컬처코드 확인하기',
    },
  ];
  return (
    <div className={cx('landing-banner-wrapper')}>
      <Carousel
        infiniteLoop
        centerMode={true}
        centerSlidePercentage={70}
        showArrows={true}
        renderArrowPrev={() => {
          return null;
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return <CarouselArrowNext clickHandler={clickHandler} hasNext={hasNext} />;
        }}
        emulateTouch={true}
        showThumbs={false}
        showStatus={false}
        interval={3000}
        showIndicators={false}
        swipeScrollTolerance={10}
      >
        {items.map((item, index) => (
          <article key={index} className={cx('carousel-item')}>
            <div className={cx('img-wrapper')} style={{ backgroundColor: item.bgColor }}>
              {item.img}
            </div>
            <div className={cx('title-wrapper')} style={{ paddingBottom: '10px' }}>
              <p className={cx('title')} style={{ marginBottom: '10px' }}>
                {item.title}
              </p>
              <LinkTag isNextPage={true} href={item.btnUrl}>
                <ButtonLinkRear mode={'borderless'} size={'lg'}>
                  {item.btnTxt}
                </ButtonLinkRear>
              </LinkTag>
            </div>
          </article>
        ))}
      </Carousel>
    </div>
  );
};

export default Landing;
